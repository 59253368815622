import axios from 'axios';
import { apiUrl } from '@/env';
import { authHeaders } from './commons';
import {
  IAndroidInfo,
  IPermission,
  IActivity,
  IService,
  IReceiver,
  IProvider,
  IIntentFilter,
  IAntiEmu,
  IFileAccess,
  INetworkAccess,
  ISharedPreference,
  IDomainInfo,
  IHostInfo,
  ILibrary,
  ICipher,
  IDexClassLoader,
  IString,
} from '@/types/analysis';

export const analysisAPI = {
  async getAndroidInfo(token: string, fileId: string) {
    return axios.get<IAndroidInfo[]>(
      `${apiUrl}/api/v1/analysis/${fileId}/android_info`,
      authHeaders(token),
    );
  },

  async getPermissions(token: string, fileId: string) {
    return axios.get<IPermission[]>(
      `${apiUrl}/api/v1/analysis/${fileId}/permissions`,
      authHeaders(token),
    );
  },

  async getActivities(token: string, fileId: string) {
    return axios.get<IActivity[]>(
      `${apiUrl}/api/v1/analysis/${fileId}/activities`,
      authHeaders(token),
    );
  },

  async getServices(token: string, fileId: string) {
    return axios.get<IService[]>(
      `${apiUrl}/api/v1/analysis/${fileId}/services`,
      authHeaders(token),
    );
  },

  async getReceivers(token: string, fileId: string) {
    return axios.get<IReceiver[]>(
      `${apiUrl}/api/v1/analysis/${fileId}/receivers`,
      authHeaders(token),
    );
  },

  async getProviders(token: string, fileId: string) {
    return axios.get<IProvider[]>(
      `${apiUrl}/api/v1/analysis/${fileId}/providers`,
      authHeaders(token),
    );
  },

  async getCerts(token: string, fileId: string) {
    return axios.get<any[]>(
      `${apiUrl}/api/v1/analysis/${fileId}/certs`,
      authHeaders(token),
    );
  },

  async getIntentFilters(token: string, fileId: string) {
    return axios.get<IIntentFilter[]>(
      `${apiUrl}/api/v1/analysis/${fileId}/intent_filters`,
      authHeaders(token),
    );
  },

  async getAntiEmus(token: string, fileId: string) {
    return axios.get<IAntiEmu[]>(
      `${apiUrl}/api/v1/analysis/${fileId}/anti_emu`,
      authHeaders(token),
    );
  },
  async getAntiRoot(token: string, fileId: string) {
    return axios.get<IAntiEmu[]>(
      `${apiUrl}/api/v1/analysis/${fileId}/anti_root`,
      authHeaders(token),
    );
  },
  async getFileInteractions(token: string, fileId: string) {
    return axios.get<IFileAccess[]>(
      `${apiUrl}/api/v1/analysis/${fileId}/file_interactions`,
      authHeaders(token),
    );
  },

  async getNetworkAccess(token: string, fileId: string) {
    return axios.get<INetworkAccess[]>(
      `${apiUrl}/api/v1/analysis/${fileId}/network`,
      authHeaders(token),
    );
  },
  async getSharedPreferences(token: string, fileId: string) {
    return axios.get<ISharedPreference[]>(
      `${apiUrl}/api/v1/analysis/${fileId}/shared_pref`,
      authHeaders(token),
    );
  },
  async getHostInfos(token: string, fileId: string) {
    return axios.get<IHostInfo[]>(
      `${apiUrl}/api/v1/analysis/${fileId}/host`,
      authHeaders(token),
    );
  },
  async getDomainInfos(token: string, fileId: string) {
    return axios.get<IDomainInfo[]>(
      `${apiUrl}/api/v1/analysis/${fileId}/domain`,
      authHeaders(token),
    );
  },

  async getLibs(token: string, fileId: string) {
    return axios.get<ILibrary[]>(
      `${apiUrl}/api/v1/analysis/${fileId}/lib`,
      authHeaders(token),
    );
  },

  async getCiphers(token: string, fileId: string) {
    return axios.get<ICipher[]>(
      `${apiUrl}/api/v1/analysis/${fileId}/cipher`,
      authHeaders(token),
    );
  },

  async getStrings(token: string, fileId: string) {
    return axios.get<IString[]>(
      `${apiUrl}/api/v1/analysis/${fileId}/str`,
      authHeaders(token),
    );
  },

  async getDexLoaders(token: string, fileId: string) {
    return axios.get<IDexClassLoader[]>(
      `${apiUrl}/api/v1/analysis/${fileId}/dexloader`,
      authHeaders(token),
    );
  },

  async getImages(token: string, fileId: string) {
    return axios.get<any[]>(
      `${apiUrl}/api/v1/analysis/${fileId}/screenshots`,
      authHeaders(token),
    );
  },
  async getPcap(token: string, fileId: string) {
    const config: any = {
      ...{
        responseType: 'blob',
      },
      ...authHeaders(token),
    };
    return axios.get<BlobPart>(
      `${apiUrl}/api/v1/analysis/${fileId}/pcap`,
      config,
    );
  },
};
