import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import RouterComponent from '@/components/RouterComponent.vue';
Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/',
    component: () =>
      import(/* webpackChunkName: "Start" */ '@/views/main/Start.vue'),
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () =>
          import(/* webpackChunkName: "Login" */ '@/views/Login.vue'),
      },
      {
        path: 'recover-password',
        component: () =>
          import(
            /* webpackChunkName: "recover-password" */ '@/views/PasswordRecovery.vue'
          ),
      },
      {
        path: 'reset-password',
        component: () =>
          import(
            /* webpackChunkName: "reset-password" */ '@/views/ResetPassword.vue'
          ),
      },
      {
        path: 'main',
        name: 'Main',
        component: () =>
          import(/* webpackChunkName: "Main" */ '@/views/main/Main.vue'),
        children: [
          {
            path: 'dashboard',
            name: 'Dashboard',
            component: () =>
              import(
                /* webpackChunkName: "Dashboard" */ '@/views/main/Dashboard.vue'
              ),
          },
          {
            path: 'profile',
            component: RouterComponent,
            redirect: 'profile/view',
            children: [
              {
                path: 'view',
                component: () =>
                  import(
                    /* webpackChunkName: "main-profile" */ '@/views/main/profile/UserProfile.vue'
                  ),
              },
              {
                path: 'edit',
                component: () =>
                  import(
                    /* webpackChunkName: "main-profile-edit" */ '@/views/main/profile/UserProfileEdit.vue'
                  ),
              },
              {
                path: 'password',
                component: () =>
                  import(
                    /* webpackChunkName: "main-profile-password" */ '@/views/main/profile/UserProfileEditPassword.vue'
                  ),
              },
            ],
          },
          {
            path: 'analyses',
            component: RouterComponent,
            redirect: 'analyses/view',
            children: [
              {
                path: 'view',
                component: () =>
                  import(
                    /* webpackChunkName: "main-analysis" */ '@/views/main/analyses/Analysis.vue'
                  ),
              },
              {
                path: 'file/:fid',
                name: 'analysis-result',
                component: () =>
                  import(
                    /* webpackChunkName: "main-analysis" */ '@/views/main/analyses/result/AnalysisDetail.vue'
                  ),
              },
              {
                path: 'monitor/:fid',
                name: 'analysis-monitor',
                component: () =>
                  import(
                    /* webpackChunkName: "main-analysis" */ '@/views/main/analyses/AnalysisMonitor.vue'
                  ),
              },
            ],
          },
          {
            path: 'admin',
            component: () =>
              import(
                /* webpackChunkName: "main-admin" */ '@/views/main/admin/Admin.vue'
              ),
            redirect: 'admin/users/all',
            children: [
              {
                path: 'users',
                redirect: 'users/all',
              },
              {
                path: 'users/all',
                component: () =>
                  import(
                    /* webpackChunkName: "main-admin-users" */ '@/views/main/admin/AdminUsers.vue'
                  ),
              },
              {
                path: 'users/edit/:id',
                name: 'main-admin-users-edit',
                component: () =>
                  import(
                    /* webpackChunkName: "main-admin-users-edit" */ '@/views/main/admin/EditUser.vue'
                  ),
              },
              {
                path: 'users/create',
                name: 'main-admin-users-create',
                component: () =>
                  import(
                    /* webpackChunkName: "main-admin-users-create" */ '@/views/main/admin/CreateUser.vue'
                  ),
              },
            ],
          },
          {
            path: '404',
            name: '404',
            component: () =>
              import(
                /* webpackChunkName: "not-found" */ '@/views/NotFound.vue'
              ),
          },
        ],
      },
    ],
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
