import { getStoreAccessors } from 'typesafe-vuex';
import { AppNotification, MainState } from './state';
import { State } from '../state';
import { IUserProfile } from '@/types/profile';

export const mutations = {
  setToken(state: MainState, token: string) {
    state.token = token;
  },
  setLogIn(state: MainState, loggedIn: boolean) {
    state.isLoggedIn = loggedIn;
  },
  setLogInError(state: MainState, loggedInError: boolean) {
    state.loggedInError = loggedInError;
  },
  setUserProfile(state: MainState, profile: IUserProfile) {
    state.userProfile = profile;
  },
  setDashboardMiniDrawer(state: MainState, dashboardMiniDrawer: boolean) {
    state.dashboardMiniDrawer = dashboardMiniDrawer;
  },
  setDashboardShowDrawer(state: MainState, dashboardShowDrawer: boolean) {
    state.dashboardShowDrawer = dashboardShowDrawer;
  },
  addNotification(state: MainState, notification: AppNotification) {
    state.notifications.push(notification);
  },
  removeNotification(state: MainState, notification: AppNotification) {
    state.notifications = state.notifications.filter((e) => e !== notification);
  },
};

const { commit } = getStoreAccessors<MainState | any, State>('');
export const commitSetToken = commit(mutations.setToken);
export const commitSetLogIn = commit(mutations.setLogIn);
export const commitSetLogInError = commit(mutations.setLogInError);
export const commitSetUserProfile = commit(mutations.setUserProfile);
export const commitSetDashboardMiniDrawer = commit(
  mutations.setDashboardMiniDrawer,
);
export const commitSetDashboardShowDrawer = commit(
  mutations.setDashboardShowDrawer,
);
export const commitAddNotification = commit(mutations.addNotification);
export const commitRemoveNotification = commit(mutations.removeNotification);
