import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { AdminState } from './state';

export const defaultState: AdminState = {
  users: [],
};

export const adminModule = {
  state: defaultState,
  mutations,
  getters,
  actions,
};
