import { userAPI } from '@/apis';
import { IUserProfileCreate, IUserProfileUpdate } from '@/types/profile';
import { getStoreAccessors } from 'typesafe-vuex';
import { ActionContext } from 'vuex';
import { dispatchCheckApiError } from '../main/actions';
import {
  commitAddNotification,
  commitRemoveNotification,
} from '../main/mutations';
import { AppNotification } from '../main/state';
import { State } from '../state';
import { commitSetUsers, commitSetUser } from './mutations';
import { AdminState } from './state';

type AdminContext = ActionContext<AdminState, State>;

export const actions = {
  async actionGetUsers(context: AdminContext) {
    try {
      const res = await userAPI.getUsers(context.rootState.main.token);
      if (res) {
        commitSetUsers(context, res.data);
      }
    } catch (err) {
      await dispatchCheckApiError(context, err);
    }
  },

  async actionCreateUser(context: AdminContext, payload: IUserProfileCreate) {
    try {
      const loadingNotification: AppNotification = {
        content: 'saving',
        showProgress: true,
      };
      commitAddNotification(context, loadingNotification);
      const res = (
        await Promise.all([
          userAPI.createUser(context.rootState.main.token, payload),
          await new Promise((resolve, reject) => setTimeout(resolve, 500)),
        ])
      )[0];
      commitSetUser(context, res.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'User created',
        color: 'success',
      });
    } catch (err) {
      dispatchCheckApiError(context, err);
    }
  },

  async actionUpdateUser(
    context: AdminContext,
    payload: {
      id: number;
      user: IUserProfileUpdate;
    },
  ) {
    try {
      const loadingNotification: AppNotification = {
        content: 'Updating',
        showProgress: true,
      };
      commitAddNotification(context, loadingNotification);
      const res = (
        await Promise.all([
          userAPI.updateUser(
            context.rootState.main.token,
            payload.id,
            payload.user,
          ),
          await new Promise((resolve, reject) => setTimeout(resolve, 500)),
        ])
      )[0];
      commitSetUser(context, res.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'User Updated',
        color: 'success',
      });
    } catch (err) {
      dispatchCheckApiError(context, err);
    }
  },
};

const { dispatch } = getStoreAccessors<AdminContext | any, State>('');
export const dispatchGetUsers = dispatch(actions.actionGetUsers);
export const dispatchCreateUser = dispatch(actions.actionCreateUser);
export const dispatchUpdateUser = dispatch(actions.actionUpdateUser);
