import { ActionContext } from 'vuex';
import { State } from '../state';
import { AppNotification, MainState } from './state';

import { userAPI } from '@/apis';
import {
  commitAddNotification,
  commitRemoveNotification,
  commitSetLogIn,
  commitSetLogInError,
  commitSetToken,
  commitSetUserProfile,
} from './mutations';
import { getStoreAccessors } from 'typesafe-vuex';
import { getLocalToken, removeLocalToken, saveLocalToken } from '@/utils';
import { AxiosError } from 'axios';
import router from '@/router';
import { IUserProfileUpdate } from '@/types/profile';

type MainContext = ActionContext<MainState, State>;
export const actions = {
  async actionLogIn(
    context: MainContext,
    payload: { username: string; password: string },
  ) {
    try {
      const res = await userAPI.logInGetToken(
        payload.username,
        payload.password,
      );
      const token = res.data.access_token;
      // console.log('token: ', token);
      if (token) {
        saveLocalToken(token);
        commitSetToken(context, token);
        commitSetLogIn(context, true);
        commitSetLogInError(context, false);
        await dispatchGetUserProfile(context, token);
        await dispatchRouteLogIn(context);
        commitAddNotification(context, {
          content: `Welcome ${payload.username}!`,
          color: 'success',
        });
      } else {
        await dispatchLogOut(context);
      }
    } catch (err) {
      commitSetLogInError(context, true);
      await dispatchLogOut(context);
    }
  },
  async actionGetUserProfile(context: MainContext, token: string) {
    try {
      const res = await userAPI.getMe(token);
      if (res.data) {
        commitSetUserProfile(context, res.data);
      }
    } catch (err) {
      await dispatchCheckApiError(context, err);
    }
  },
  async actionUpdateUserProfile(
    context: MainContext,
    payload: IUserProfileUpdate,
  ) {
    // console.log('Update: ', payload);
    try {
      const loadingNotification: AppNotification = {
        content: 'Saving',
        showProgress: true,
      };
      commitAddNotification(context, loadingNotification);
      const res = (
        await Promise.all([
          userAPI.updateMe(context.state.token, payload),
          await new Promise((resolve, reject) =>
            setTimeout(() => resolve(true), 500),
          ),
        ])
      )[0];
      commitSetUserProfile(context, res.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'Profile updated',
        color: 'success',
      });
    } catch (err) {
      await dispatchCheckApiError(context, err);
    }
  },
  async actionCheckApiError(context: MainContext, payload: AxiosError) {
    if (payload.response!.status === 401) {
      await dispatchLogOut(context);
    }
  },
  async actionRouteLogOut(context: MainContext) {
    if (router.currentRoute.path !== '/login') {
      router.push('/');
    }
  },
  async actionRouteLogIn(context: MainContext) {
    if (
      router.currentRoute.path === '/login' ||
      router.currentRoute.path === ''
    ) {
      router.push('/main/analyses/view');
    }
  },
  async actionLogOut(context: MainContext) {
    await dispatchRemoveLogin(context);
    await dispatchRouteLogOut(context);
  },
  async actionRemoveLogin(context: MainContext) {
    commitSetToken(context, '');
    removeLocalToken();
    commitSetLogIn(context, false);
    // commitSetLogInError(context, false);
  },

  async actionCheckLoggedIn(context: MainContext) {
    if (!context.state.isLoggedIn) {
      let token = context.state.token;
      if (!token) {
        const tokenLocal = getLocalToken();
        if (tokenLocal) {
          commitSetToken(context, tokenLocal);
          token = tokenLocal;
        }
      }

      if (token) {
        try {
          const res = await userAPI.getMe(token);
          commitSetLogIn(context, true);
          commitSetUserProfile(context, res.data);
        } catch (err) {
          await dispatchRemoveLogin(context);
        }
      } else {
        await dispatchRemoveLogin(context);
      }
    }
  },

  async removeNotification(
    context: MainContext,
    payload: { notification: AppNotification; timeout: number },
  ) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        commitRemoveNotification(context, payload.notification);
        resolve(true);
      }, payload.timeout);
    });
  },

  async passwordRecovery(context: MainContext, payload: { username: string }) {
    const loadingNotification: AppNotification = {
      content: 'Sending password recover email',
      showProgress: true,
    };
    try {
      commitAddNotification(context, loadingNotification);
      const res = await Promise.all([
        userAPI.recoverPassword(payload.username),
        await new Promise((resolve, reject) => setTimeout(resolve, 500)),
      ])[0];
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'Password recovery email sent',
        color: 'success',
      });
      await dispatchLogOut(context);
    } catch (err) {
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'Incorrect username',
        color: 'error',
      });
    }
  },
  async resetPassword(
    context: MainContext,
    payload: { password: string; token: string },
  ) {
    const loadingNotification: AppNotification = {
      content: 'Resetting password',
      showProgress: true,
    };
    try {
      const res = await Promise.all([
        userAPI.resetPassword(payload.token, payload.password),
        await new Promise((resolve, reject) => setTimeout(resolve, 500)),
      ])[0];
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'Reset Password successfully',
        color: 'success',
      });
      await dispatchLogOut(context);
    } catch (err) {
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'Error resetting password',
        color: 'error',
      });
    }
  },
};

const { dispatch } = getStoreAccessors<MainState | any, State>('');
export const dispatchLogIn = dispatch(actions.actionLogIn);
export const dispatchLogOut = dispatch(actions.actionLogOut);
export const dispatchRemoveLogin = dispatch(actions.actionRemoveLogin);
export const dispatchRouteLogIn = dispatch(actions.actionRouteLogIn);
export const dispatchRouteLogOut = dispatch(actions.actionRouteLogOut);
export const dispatchCheckLoggedIn = dispatch(actions.actionCheckLoggedIn);
export const dispatchCheckApiError = dispatch(actions.actionCheckApiError);
export const dispatchGetUserProfile = dispatch(actions.actionGetUserProfile);
export const dispatchUpdateUserProfile = dispatch(
  actions.actionUpdateUserProfile,
);
export const dispatchPasswordRecovery = dispatch(actions.passwordRecovery);
export const dispatchResetPassword = dispatch(actions.resetPassword);
export const dispatchRemoveNotification = dispatch(actions.removeNotification);
