import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import { AdminState } from './state';

export const getters =  {
    adminUsers: (state: AdminState) => state.users,
    adminOneUser: (state: AdminState) => (userId: number) => {
        const filteredUser = state.users.filter((e) => e.id === userId);
        if (filteredUser.length > 0) {
            return {...filteredUser[0] };
        }
    },
};

const { read } = getStoreAccessors<AdminState|any, State>('');

export const readAdminOneUser = read(getters.adminOneUser);
export const readAdminUsers = read(getters.adminUsers);
