import { IUserProfile } from '@/types/profile';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import { AdminState } from './state';

export const mutations = {
  setUsers(state: AdminState, users: IUserProfile[]) {
    state.users = users;
  },

  setUser(state: AdminState, user: IUserProfile) {
    const users = state.users.filter((e) => e.id !== user.id);
    users.push(user);
    state.users = users;
  },
};

const { commit } = getStoreAccessors<AdminState, State>('');

export const commitSetUser = commit(mutations.setUser);
export const commitSetUsers = commit(mutations.setUsers);
