






















import { Component, Vue } from 'vue-property-decorator';
import NotificationManager from '@/components/NotificationManager.vue';
import { readIsLoggedIn } from './store/main/getters';
import { dispatchCheckLoggedIn } from './store/main/actions';

@Component({
  components: {
    NotificationManager,
  },
})
export default class App extends Vue {
  get isLoggedIn() {
    return readIsLoggedIn(this.$store);
  }

  public async created() {
    dispatchCheckLoggedIn(this.$store);
  }
}
