import axios from 'axios';
import { apiUrl } from '@/env';
import { authHeaders } from './commons';
import {
  IUserProfile,
  IUserProfileUpdate,
  IUserProfileCreate,
} from '@/types/profile';

export const userAPI = {
  async logInGetToken(username: string, password: string) {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);

    return axios.post(`${apiUrl}/api/v1/login/access-token`, params);
  },

  async getMe(token: string) {
    return axios.get<IUserProfile>(
      `${apiUrl}/api/v1/users/me`,
      authHeaders(token),
    );
  },

  async updateMe(token: string, data: IUserProfileUpdate) {
    return axios.put(`${apiUrl}/api/v1/users/me`, data, authHeaders(token));
  },

  async getUsers(token: string) {
    return axios.get<IUserProfile[]>(
      `${apiUrl}/api/v1/users`,
      authHeaders(token),
    );
  },
  async updateUser(token: string, id: number, data: IUserProfileUpdate) {
    return axios.put(`${apiUrl}/api/v1/users/${id}`, data, authHeaders(token));
  },
  async createUser(token: string, data: IUserProfileCreate) {
    return axios.post(`${apiUrl}/api/v1/users`, data, authHeaders(token));
  },
  async recoverPassword(email: string) {
    return axios.post(`${apiUrl}/api/v1/password-recovery/${email}`);
  },
  async resetPassword(token: string, password: string) {
    return axios.post(`${apiUrl}/api/v1/reset-password`, {
      new_password: password,
      token,
    });
  },
};
