import axios from 'axios';
import { apiUrl } from '@/env';
import { authHeaders } from './commons';

export const taskAPI = {
  async getStatus(token: string, taskId: string) {
    return axios.get<any>(
      `${apiUrl}/api/v1/tasks/${taskId}`,
      authHeaders(token),
    );
  },
};
