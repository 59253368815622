











import {
  dispatchRemoveLogin,
  dispatchRemoveNotification,
} from '@/store/main/actions';
import { readFirstNotification } from '@/store/main/getters';
import {
  commitAddNotification,
  commitRemoveNotification,
} from '@/store/main/mutations';
import { AppNotification } from '@/store/main/state';
import { Component, Vue, Watch } from 'vue-property-decorator';
@Component
export default class NotificationManager extends Vue {
  public show: boolean = false;
  public text: string = '';
  public showProgress: boolean = false;
  public currentNotification: AppNotification | false = false;

  public async close() {
    await this.hide();
    await this.removeCurrentNotification();
  }

  public async setNotification(notificaton: AppNotification | false) {
    if (this.show) {
      this.hide();
    }
    if (notificaton) {
      this.currentNotification = notificaton;
      this.showProgress = notificaton.showProgress || false;
      this.show = true;
    } else {
      this.currentNotification = false;
    }
  }

  public async hide() {
    this.show = false;
    await new Promise((resolve, reject) => setTimeout(resolve, 500));
  }

  public async removeCurrentNotification() {
    if (this.currentNotification) {
      commitRemoveNotification(this.$store, this.currentNotification);
    }
  }

  public get firstNotification() {
    return readFirstNotification(this.$store);
  }

  @Watch('firstNotification')
  public async onNotificationChange(
    newNotification: AppNotification | false,
    oldNotification: AppNotification | false,
  ) {
    if (newNotification !== this.currentNotification) {
      await this.setNotification(newNotification);
      if (newNotification) {
        dispatchRemoveNotification(this.$store, {
          notification: newNotification,
          timeout: 6500,
        });
      }
    }
  }

  public get currentNotifcationContent() {
    return (this.currentNotification && this.currentNotification.content) || '';
  }

  public get currentNotificationColor() {
    return (
      (this.currentNotification && this.currentNotification.color) || 'info'
    );
  }
}
