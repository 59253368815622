import axios from 'axios';
import { apiUrl } from '@/env';
import { authHeaders } from './commons';

interface ITask {
  sha256: string;
  task_id: string;
}

export const fileAPI = {
  async getFile(token: string, fileId: string) {
    return axios.get<string>(
      `${apiUrl}/api/v1/files/${fileId}`,
      authHeaders(token),
    );
  },
  async rescan(token: string, fileId: string) {
    return axios.post<ITask>(
      `${apiUrl}/api/v1/files/${fileId}/rescan`,
      null,
      authHeaders(token),
    );
  },
  async uploadFile(token: string, formData: any, onProgressUpdate: any) {
    const headers: any = {
      ...{
        headers: {
          'Content-Type': 'multipart/form-data',
        },

        onUploadProgress: onProgressUpdate,
      },
      ...authHeaders(token),
    };
    return axios.post(`${apiUrl}/api/v1/files`, formData, headers);
  },
};
